<template>
  <div class="flex-grow-1">
    <UserTitle hideBack>
      <template v-slot:actions>
          <div>
            <h2 class="text-h3 font-weight-black">Withdraw An Application</h2>
          </div>
      </template>
    </UserTitle>

    <!-- Filters -->
    <div class="mb-15">
      <v-row>
        <v-col cols="9" class="jobs-section"> 
          
          <preloader v-if="jobStatus.getting" />
          <div class="text-center" v-if="jobs.length == 0">
              No records found.
          </div>
          <div v-if="jobs">
            <JobsCard 
            v-for="job in ordered"
            :key="job.id"
            :job="job"
            withdrawn
            />
          </div>
        </v-col>

        <v-col cols="3">
          <div>
              <AppliesFilter @sortOrder="filterOrder" />
          </div>
        </v-col>

      </v-row>
    </div>
    <!-- End Filters -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppliesFilter from '@/components/dashboard/apply/AppliesFilter'
// import JobsCard from '@/components/dashboard/job/JobsCard'
import JobsCard from '@/views/job/JobCard'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'Jobs',

  metaInfo: {
    title: 'Jobs'
  },

    data() {
    return {
      confirm: true,
      deleteMessage: 'Deleting message',
      deleting: false,
      toDelete: null,
      locationFilterValue: null,
      skillsFilterValue: null,
      remoteFilterValue: null,
      sortJobs: 'desc',
      order: 'createdAt',
      status: {
          deleting: false
      },
      orderBy: 'desc'
    }
  },

  components: {
    AppliesFilter,
    JobsCard,
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
      jobs: state => state.apply.withdrawApplied,
      jobStatus: state => state.apply.status,
    }),

    ordered: function () {
      return _orderBy(this.jobs, 'createdAt', this.orderBy)
    },

  },

  methods: {
    ...mapActions('apply', [
        'getWithdrawJob',
    ]),
    

    sortDate(value) {
      this.sortJobs = value == 'asc'? 'asc' : 'desc'
    },

    skillsFilter(value) {
      this.skillsFilterValue = value
    },

    locationsFilter(value) {
      this.locationFilterValue = value
    },

    remoteFilter(value) {
      this.remoteFilterValue = value
    },

    filterOrder(sortData) {
      this.orderBy = sortData.order
    },

    getJobStatus(job) {
      let data = []
      if(this.jobApplied){
        data = _find(this.jobApplied, function(applied) { 
          return applied.jobId == job.id 
        })
      }
      return data.status
    },

  },
  
  created() {
    // if (!this.jobStatus.firstLoad) this.getApplies()
    this.getWithdrawJob()
  }
}
</script>
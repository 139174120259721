<template>
    <div class="pl-4 pr-4">
        <v-card outlined class="shadow-lg">
            <v-card-title class="heading-font primary--text font-weight-black">
            Refine Search
            </v-card-title>
            <v-card-text>
                <div class="d-flex gap-12 align-center">
                <v-sheet>
                    <div class="mb-5">
                        <span class="body-lg font-weight-bold">Display: </span>
                        <v-select
                        @change="getApplies()"
                        v-model="status.displayRange"
                        :items="displayRanges"
                        class="dashboard-select"
                        item-text="name"
                        item-value="value"
                        dense
                        ></v-select>
                    </div>
                    <div class="mb-5">
                        <span class="body-lg font-weight-bold">Sort By: </span>
                        <v-select
                        @change="sort()"
                        v-model="order"
                        :items="orderBy"
                        item-text="name"
                        item-value="value"
                        placeholder="Date Created"
                        hide-details
                        class="dashboard-select"
                        dense
                        ></v-select>
                    </div>
                    <div class="mt-4">
                        <v-btn
                        @click="clear()"
                        color="grey" 
                        text>
                            <v-icon left dark>mdi-close-circle-outline</v-icon>
                            <span class="text-none font-weight-medium no-spacing">Clear Filter</span>
                        </v-btn>
                    </div>
                </v-sheet>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data() {
        return {
            order: 'desc',
            displayRanges: [
            {
              name: 'Last 60 Days',
              value: 60
            },
            {
              name: 'Last 30 Days',
              value: 30
            },
            {
              name: 'Last 7 Days',
              value: 7
            },
            {
              name: 'All',
              value: 0
            },
          ],
          orderBy: [
            {
              name: 'Newest First',
              value: 'desc'
            },
            {
              name: 'Oldest',
              value: 'asc'
            }
          ]
        }
    },

/* -------------------------------------------------------------------------- */
/*                                  COMPUTED                                  */
/* -------------------------------------------------------------------------- */
    computed: {
        ...mapState({
            status: state => state.apply.status,
        }),
    },

/* -------------------------------------------------------------------------- */
/*                                   METHODS                                  */
/* -------------------------------------------------------------------------- */
    methods: {    
        ...mapActions('apply', [
            'getApplies',
        ]),

        sort() {
            this.$emit('sortOrder', { order : this.order})
        },

        clear() {
            this.getApplies()
        }
    },
}
</script>
